// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buycrypto-js": () => import("/opt/build/repo/src/pages/buycrypto.js" /* webpackChunkName: "component---src-pages-buycrypto-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-discord-js": () => import("/opt/build/repo/src/pages/discord.js" /* webpackChunkName: "component---src-pages-discord-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-pro-js": () => import("/opt/build/repo/src/pages/pro.js" /* webpackChunkName: "component---src-pages-pro-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("/opt/build/repo/src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-whitelabel-js": () => import("/opt/build/repo/src/pages/whitelabel.js" /* webpackChunkName: "component---src-pages-whitelabel-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

